import React from "react";
import { animated, SpringValue } from "@react-spring/web";
import { Typography } from "antd";

const { Text } = Typography;
const PublicSwitchPrimaryButton = ({
  icon,
  label,
  color,
  background,
  size = "small",
  style,
}: {
  icon?: React.ReactNode;
  label: string;
  color: string;
  background: string | SpringValue<any>;
  size?: "small" | "large" | "extraSmall";
  style?: React.CSSProperties;
}) => {
  let iconFontSize = 14;
  let padding = "8px 16px";
  let fontSize = 14;

  if (size === "large") {
    iconFontSize = 16;
    padding = "12px 23px";
    fontSize = 16;
  } else if (size === "extraSmall") {
    iconFontSize = 12;
    padding = "6px 10px";
    fontSize = 13;
  }

  return (
    <animated.button
      style={{
        background,
        border: "1px solid white",
        cursor: "pointer",
        padding,
        borderRadius: 50,
        ...style,
      }}
    >
      {icon && (
        <Text
          style={{
            color,
            fontSize: iconFontSize,
            fontWeight: 600,
            marginRight: 8,
          }}
        >
          {icon}
        </Text>
      )}
      <Text
        style={{
          color,
          fontSize,
          fontWeight: 600,
        }}
      >
        {label}
      </Text>
    </animated.button>
  );
};

export default PublicSwitchPrimaryButton;
