"use client";

import React, { createContext, ReactNode, useContext } from "react";
import { PublicKpi } from "@koble/api/public/getPublicKpis";
import { Job } from "@koble/graphql";
import { IdentityUserType } from "@koble/utils/src/UserSessionManager";
import { usePathname } from "next/navigation";

import es from "@/lang/es";

export interface PublicSwitchPageContextProps {
  pageType: IdentityUserType;
  jobs: Job[];
  kpis: PublicKpi;
}

export const PublicSwitchPageContext =
  createContext<PublicSwitchPageContextProps>({
    pageType: "userStudent",
    jobs: [],
    kpis: {
      totalBusinesses: 0,
      totalMatches: 0,
      totalUniversities: 0,
      totalUserStudents: 0,
    },
  });

export const usePublicSwitchPage = () => useContext(PublicSwitchPageContext);

export const PublicSwitchPageProvider = ({
  children,
  jobs,
  kpis,
}: {
  children: ReactNode;
  jobs: Job[];
  kpis: PublicKpi;
}) => {
  const pathName = usePathname();

  let pageType: IdentityUserType = "userStudent";

  if (
    pathName === es.switch.metadata.userStudentSlug ||
    pathName === "/switch/user-student"
  )
    pageType = "userStudent";

  if (
    pathName === es.switch.metadata.userRecruiterSlug ||
    pathName === "/switch/user-recruiter"
  )
    pageType = "userRecruiter";

  return (
    <PublicSwitchPageContext.Provider
      value={{
        pageType,
        jobs,
        kpis,
      }}
    >
      {children}
    </PublicSwitchPageContext.Provider>
  );
};
