import React from "react";
import { useElementOnScreen } from "@koble/hooks";
import { animated, useSpring } from "@react-spring/web";

type Props = {
  children: React.ReactNode;
  reappear?: boolean;
  threshold?: number;
  style?: React.CSSProperties;
  direction?: "topToBottom" | "bottomToTop" | "leftToRight" | "rightToLeft";
  delay?: number;
};

const AnimateOnScroll = ({
  children,
  reappear,
  style,
  threshold = 0.5,
  direction,
  delay = 0,
}: Props) => {
  const [containerRef, isVisible] = useElementOnScreen({
    threshold,
    reappear,
  });

  let from;
  let to;

  switch (direction) {
    case "topToBottom":
      from = "translateY(-50px)";
      to = "translateY(0)";
      break;
    case "bottomToTop":
      from = "translateY(50px)";
      to = "translateY(0)";
      break;
    case "leftToRight":
      from = "translateX(-50px)";
      to = "translateX(0)";
      break;
    case "rightToLeft":
      from = "translateX(50px)";
      to = "translateX(0)";
      break;
    default:
      break;
  }

  const { opacity, transform } = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? to : from,
    delay,
  });

  return (
    <>
      <animated.div
        ref={containerRef}
        style={{
          opacity,
          transform,
          ...style,
        }}
      >
        {children}
      </animated.div>
    </>
  );
};

export default AnimateOnScroll;
