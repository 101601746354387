import {
  PUBLIC_BLUE_GRADIENT_FROM_COLOR,
  PUBLIC_BLUE_GRADIENT_TO_COLOR,
  PUBLIC_GRAY_GRADIENT_FROM_COLOR,
  PUBLIC_GRAY_GRADIENT_TO_COLOR,
  PUBLIC_ORANGE_GRADIENT_FROM_COLOR,
  PUBLIC_ORANGE_GRADIENT_TO_COLOR,
} from "@/common/publicColors";

const generatePublicLinearGradient = ({
  colorType,
  angle,
}: {
  colorType: "orange" | "blue" | "gray";
  angle: number;
}) => {
  if (colorType === "blue") {
    return `linear-gradient(${angle}deg, ${PUBLIC_BLUE_GRADIENT_FROM_COLOR} 0%, ${PUBLIC_BLUE_GRADIENT_TO_COLOR} 100%)`;
  }

  if (colorType === "orange") {
    return `linear-gradient(${angle}deg, ${PUBLIC_ORANGE_GRADIENT_FROM_COLOR} 0%, ${PUBLIC_ORANGE_GRADIENT_TO_COLOR} 100%)`;
  }

  if (colorType === "gray") {
    return `linear-gradient(${angle}deg, ${PUBLIC_GRAY_GRADIENT_FROM_COLOR} 0%, ${PUBLIC_GRAY_GRADIENT_TO_COLOR} 100%)`;
  }

  return "";
};

export default generatePublicLinearGradient;
